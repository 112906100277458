import { Component } from '@angular/core';
import { InputComponent } from '../../../ui-components/input/input.component';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TextareaComponent } from '../../../ui-components/textarea/textarea.component';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'mars-contact-us-form',
  standalone: true,
  imports: [
    InputComponent,
    ReactiveFormsModule,
    TextareaComponent,
    TranslocoModule,
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
})
export class ContactUsComponent {
  form = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', Validators.required),
    message: new FormControl(''),
  });
}
